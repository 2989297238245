const socialLinks = {
  instagram: {
    link: "https://instagram.com/the__lounge__",
    ariaLabel: "Instagram",
  },
  facebook: {
    link: "https://www.facebook.com/theloungeplzen",
    ariaLabel: "Facebook",
  },
};

export default socialLinks;
